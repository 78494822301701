// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-anleitung-js": () => import("./../../../src/pages/anleitung.js" /* webpackChunkName: "component---src-pages-anleitung-js" */),
  "component---src-pages-belege-js": () => import("./../../../src/pages/belege.js" /* webpackChunkName: "component---src-pages-belege-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-dataprotection-js": () => import("./../../../src/pages/dataprotection.js" /* webpackChunkName: "component---src-pages-dataprotection-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-release-notes-js": () => import("./../../../src/pages/release-notes.js" /* webpackChunkName: "component---src-pages-release-notes-js" */),
  "component---src-pages-tos-js": () => import("./../../../src/pages/tos.js" /* webpackChunkName: "component---src-pages-tos-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-docs-page-js": () => import("./../../../src/templates/docs-page.js" /* webpackChunkName: "component---src-templates-docs-page-js" */)
}

